import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["canvas"];

  connect() {
    this.canvas = this.canvasTarget;
    this.ctx = this.canvas.getContext("2d");

    this.resizeCanvas();
    window.addEventListener("resize", this.resizeCanvas.bind(this));

    this.mouse = { x: null, y: null };
    window.addEventListener("mousemove", this.updateMousePosition.bind(this));

    this.initializeParticles();
    this.animate();
  }

  disconnect() {
    window.removeEventListener("resize", this.resizeCanvas.bind(this));
    window.removeEventListener("mousemove", this.updateMousePosition.bind(this));
  }

  resizeCanvas() {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;

    this.initializeParticles();
  }

  initializeParticles() {
    this.particleArray = [];
    const gridSpacing = 50;

    for (let y = 0; y < this.canvas.height; y += gridSpacing) {
      for (let x = 0; x < this.canvas.width; x += gridSpacing) {
        this.particleArray.push(new Particle(this.ctx, x, y));
      }
    }
  }

  updateMousePosition(event) {
    this.mouse.x = event.clientX;
    this.mouse.y = event.clientY;
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.particleArray.forEach((particle) => {
      particle.update(this.mouse);
    });
  }
}

class Particle {
  constructor(ctx, x, y) {
    this.ctx = ctx;
    this.baseX = x;
    this.baseY = y;
    this.x = x;
    this.y = y;
    this.size = 3;
    this.angle = 0;
    this.speed = Math.random() * 0.05 + 0.02;
    this.color = this.getRandomColor();
  }

  update(mouse) {
    if (mouse.x && mouse.y) {
      const dx = mouse.x - this.x;
      const dy = mouse.y - this.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const maxDistance = 200;

      if (distance < maxDistance) {
        const force = (maxDistance - distance) / maxDistance;
        const directionX = dx / distance;
        const directionY = dy / distance;

        this.x += directionX * force * 10;
        this.y += directionY * force * 10;
      } else {
        this.x += (this.baseX - this.x) * 0.05;
        this.y += (this.baseY - this.y) * 0.05;
      }
    }

    this.angle += this.speed;
    this.draw();
  }

  draw() {
    this.ctx.save();
    this.ctx.translate(this.x, this.y);
    this.ctx.rotate(this.angle);

    // Draw geometric shape (hexagon)
    this.ctx.beginPath();
    for (let i = 0; i < 6; i++) {
      this.ctx.lineTo(
          this.size * Math.cos((i * Math.PI) / 3),
          this.size * Math.sin((i * Math.PI) / 3)
      );
    }
    this.ctx.closePath();

    this.ctx.fillStyle = this.color;
    this.ctx.shadowColor = this.color;
    this.ctx.shadowBlur = 15;
    this.ctx.fill();
    this.ctx.restore();
  }

  getRandomColor() {
    const colors = ["#00FFB3", "#FF007F", "#8F00FF", "#00FFFF", "#FFD700"];
    return colors[Math.floor(Math.random() * colors.length)];
  }
}
